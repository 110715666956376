<template>
  <div class="auth-wrapper">
    <div class="auth-inner">
      <form class="form" @submit.prevent="save">
        <h3 class="title">Ro'yxatdan o'tish</h3>
        <div class="form-group">
          <label>Username</label>
          <input
            v-model="form.username"
            required
            autofocus
            type="text"
            placeholder="Ismingizni kiriting"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label>Parol</label>
          <div class="view-password d-flex">
            <input
              :type="inputType"
              v-model="form.password"
              minLength="8"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group">
          <label>Parolni takrorlang</label>
          <div class="view-password d-flex">
            <input
              :type="inputType"
              v-model="form.password_repeat"
              minLength="8"
              class="form-control"
            />
            <button
              @click="toggleVisible"
              tabIndex="-1"
              type="button"
              class="btn btn-eye ml-2 btn-orange"
            >
              <i class="fas fa-eye"></i>
            </button>
          </div>
        </div>
        <button class="btn btn-orange btn-block">Saqlash</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "finish",
  data: function () {
    return {
      visible: false,
      form: {
        username: "",
        password: "",
        password_repeat: "",
        token: "",
      },
    };
  },
  computed: {
    tempToken() {
      return this.$store.getters["user/getTempToken"];
    },
    inputType() {
      return this.visible ? "text" : "password";
    },
  },
  methods: {
    toggleVisible: function () {
      this.visible = !this.visible;
    },
    save: async function () {
      this.form.token = this.tempToken;
      if (await this.$store.dispatch("user/finish", this.form)) {
        this.$toast.success("Ro'yxatdan o'tdingiz");
        await this.$store.dispatch("user/getData");
        await this.$router.push({ name: "home" });
      }
    },
  },
  created() {
    if (this.tempToken === "") {
      this.$router.push({ name: "register" });
    }
  },
};
</script>

<style scoped></style>
